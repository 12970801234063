<template>
	<div class="pricing_container">
		<div id="pricing_content1">
			<div class="content1_title">
				<h1>
					내 비즈니스 유형에<br />
					<span>적합한 플랜을 찾아보세요</span>
				</h1>
				<p>
					FCTS에서는 사용한 만큼 개별 서비스에 월별 기간으로 비용을 지불하면
					되어<br />
					추가 비용이나 종료 비용 없는 장점이 있습니다.
				</p>
			</div>
			<div class="content1_contentbox">
				<div class="TabletOfBox">
					<div class="box">
						<div class="MainName">데이터 분석 프레임워크</div>
						<div class="ServeService">
							실시간 데이터 변화<br />
							가시화 서비스
						</div>
						<div class="border"></div>
						<h2>모듈러스</h2>
						<router-link to="/pricingsecurity">
							<button>상세요금 바로가기</button>
						</router-link>
						<p>사용량 무제한</p>
					</div>
					<div class="box">
						<div class="MainName">데이터 시각화 툴</div>
						<div class="ServeService">
							데이터 시각화 기반의<br />
							업무 자동화 툴
						</div>
						<div class="border"></div>
						<h2>BI-Mining</h2>
						<router-link to="/pricingbimining">
							<button>상세요금 바로가기</button>
						</router-link>
						<router-link to="/inquiry">
							<p>서비스 1:1 문의하기</p>
						</router-link>
					</div>
				</div>
				<div class="TabletOfBox">
					<div class="box">
						<div class="MainName">데이터를 안전하게 보호</div>
						<div class="ServeService">
							데이터를 암호화하여<br />
							보호하는 솔루션
						</div>
						<div class="border"></div>
						<h2>암호 스키마</h2>
						<router-link to="/solutioncrypto">
							<button>솔루션 바로가기</button>
						</router-link>
						<router-link to="/inquiry">
							<p>서비스 1:1 문의하기</p>
						</router-link>
					</div>
					<div class="box">
						<div class="MainName">알고리즘 적용 백신</div>
						<div class="ServeService">
							머신러닝을 활용한<br />
							백신 솔루션
						</div>
						<div class="border"></div>
						<h2>FC-Anti Virus</h2>
						<router-link to="/solutionvaccine">
							<button>솔루션 바로가기</button>
						</router-link>
						<router-link to="/inquiry">
							<p>서비스 1:1 문의하기</p>
						</router-link>
					</div>
				</div>
				<!--content1_contentbox end-->
			</div>
			<!--pricing_content1_title end-->
		</div>
		<!--pricing_content1 end-->

		<div id="pricing_content2">
			<div class="content2_Top">문의를 통해 빠른 안내를 받아보세요!</div>
			<div class="content2_Main">
				<div class="MainToTop">
					제품 이용에 궁금한 점이 있으시다면 문의사항을 남겨주세요<br />
					빠른 시일내 연락을 드리겠습니다.
				</div>

				<div class="content2_BtnBox">
					<router-link to="/inquiry">
						<button class="askBox">1:1 문의하기</button>
					</router-link>
					<router-link to="/userguide">
						<button class="asksBox">제품 사용 방법</button>
					</router-link>
				</div>
				<div class="content2_ServiceDay">
					상담시간 : 평일 10:00 ~ 17:00 (주말/공휴일 휴무)<br />
					이메일 상담 : 24시간 가능<br />
					<span>대표 번호 : 010-3901-4816</span>
				</div>
			</div>
		</div>

		<div class="Mobile_Pricing">
			<div class="Pricing__Header">
				<div class="Head1">내 비즈니스 유형에</div>
				<div class="Head2">적합한 플랜을 찾아보세요</div>

				<div class="contentss">
					FCTS에서는 사용한 만큼 개별 서비스에 월별 기간으로 비용을<br />
					지불하면 되어 추가 비용이나 종료 비용 없는 장점이 있습니다.
				</div>
				<router-link to="/pricingsecurity">
					<button class="PriceBtn">모듈러스 요금 확인하기</button>
				</router-link>
				<router-link to="/pricingbimining">
					<button class="PriceBtn">BI Mining 요금 확인하기</button>
				</router-link>
				<router-link to="/solutioncrypto">
					<button class="PriceBtn">암호스키마 솔루션 바로가기</button>
				</router-link>
				<router-link to="/solutionvaccine">
					<button class="PriceBtn">FC-Anty Virus 솔루션 바로가기</button>
				</router-link>
			</div>

			<div class="Pricing_1">
				<div class="MbPricing_Ask">문의를 통해 빠른 안내를 받아보세요!</div>
				<div class="MbPricing_Fl">
					제품 이용에 궁금한 점이 있으시다면<br />
					문의 사항을 남겨 주세요.<br />
					빠른 시일 내 연락을 드리겠습니다.
				</div>
				<div class="MbPricing_Time">
					상담시간 : 평일 10:00 ~ 17:00 (주말/공휴일 휴무) <br />이메일 상담 :
					24시간 가능
				</div>
				<div class="MbPricing_btnBox">
					<button class="MbPricing_btn">010-3901-4816</button>
					<router-link to="/inquiry">
						<button class="MbPricing_btn">1:1 문의하기</button>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import pricing_content2img from '@/assets/images/pricing/pricing_content2.svg';
import pricing_content4img from '@/assets/images/pricing/pricing_content4.svg';
import pricing_content2img2 from '@/assets/images/pricing/pricing_content2_mobile.svg';
export default {
	data: function () {
		return {
			pricing_content2img,
			pricing_content4img,
			pricing_content2img2,
		};
	},
};
</script>

<style></style>
