<template>
	<div>
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<pricing-content-vue></pricing-content-vue>
		<Footer />
	</div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';
import MenuIntro from '@/components/common/MenuIntro.vue';
import PricingContentVue from '../components/PricingContent.vue';
export default {
	components: { MainHeader, Footer, PricingContentVue, MenuIntro },
	data() {
		return {
			menuKr: '요금 안내',
			menuEn: 'Price',
		};
	},
};
</script>

<style></style>
